const UPC_REGEX = /^(\d{8}|\d{12}|\d{13})$/;  // UPC (12 digits) and EAN (8 or 13 digits)
const SCIENTIFIC_NOTATION_REGEX = /^[-+]?[0-9]*\.?[0-9]+[eE][-+]?[0-9]+$/;

const MAX_VALID_YEAR = 9999;

export function textIsEmpty(text) {
    const emptyRe = /^\s*$/
    return (!text || emptyRe.test(text));
}
export function validateRangeNumBetween0to100(num) {
    const range = /^(\d{1,2}(\.\d{1,5})?|100(\.0+)?)$/
    
    return (!num || !range.test(num));
}

export function textIsDate(text) {
    const date = new Date(text);
    return !isNaN(date) && date.getFullYear() <= MAX_VALID_YEAR;
}

export function textIsUpc(text) {
    return UPC_REGEX.test(text);
}

export function textIsIsrc(text) {
    const isrcReA = /^\s*[A-Za-z]{2}-\w{3}-\d{2}-\d{5}\s*$/g;
    const isrcReB = /^\s*[A-Za-z]{2}\w{3}\d{2}\d{5}\s*$/g;

    return isrcReA.test(text) || isrcReB.test(text);
}

export function textIsNumber(text) {
    return !isNaN(Number(text));
}

export function textIsScientificNumber(text) {
    return SCIENTIFIC_NOTATION_REGEX.test(text);
}

export function textIsYearQuarter(text) {
    const yearQuarterRe = /^[12][0-9]{3}[1234]$/g;
    return yearQuarterRe.test(text);
}

export function cellTextIsEmpty(text) {
    const emptyRe = /^\s*$/
    return (!text || emptyRe.test(text)) || text === 'No Data';
}

export function textIsEmail(text) {
    //taken from top answer in this post https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
    const emailRe = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return emailRe.test(text);
}

export function textIsPassword(text) {
    const digitRe = /\d+/;
    const lowerCaseRe = /[a-z]+/;
    const upperCaseRe = /[A-Z]+/;
    const specialSymbolRe = /[^0-9A-Za-z]+/;
    if (!digitRe.test(text)) {
        return 'Password must contain at least 1 digit.'
    }
    if (!lowerCaseRe.test(text)) {
        return 'Password must contain at least 1 lower case letter.'
    }
    if (!upperCaseRe.test(text)) {
        return 'Password must contain at least 1 upper case letter.'
    }
    if (!specialSymbolRe.test(text)) {
        return 'Password must contain at least 1 special symbol.'
    }
    if (text.length < 8) {
        return 'Password length must be at least 8 symbols.'
    }
    return true;
}

export function isCSVFile(file) {
    const { name } = file;
    return name.toLowerCase().endsWith('.csv');
};

export function checkCellError(wrongFunc) {
    switch (wrongFunc) {
        case 'date':
            return 'Wrong Date format (ex. YYYY-MM-DD, 2020-12-25) or the Date range is invalid';
        case 'upc':
            return 'Wrong UPC format';
        case 'isrc':
            return 'Wrong ISRC format (ex. USKO10701678)';
        case 'number':
            return 'Wrong numeric format (only numbers are allowed)';
        case 'yearQuarter':
            return 'Wrong YearQuarter format (ex. 20231, 20232, 20233, 20234)';
        case 'textIsPercent':
            return 'Wrong number range (must be between 0 and 100)';
        default: return '';
    };
};

/**
 * Updates the CSS class of an element based on its ID.
 *
 * @param {string} element - The ID of the element to update.
 * @param {string} className - The CSS class name to add or remove.
 * @param {boolean} [remove=false] - If true, the class will be removed; if false, it will be added.
 */
export function updateColumnClass(element, className, remove = false) {
    const domElement = document.getElementById(element);

    if (domElement) {
        domElement.classList.toggle(className, !remove);
    }
}
