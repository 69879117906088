import React, { useEffect, useRef, useState } from 'react';
import { BrandMultiSelect } from '../CoreComponents/BrandSelect'
import { TempBrandButton, TempBrandDeleteButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton'
import { makeStyles } from '@material-ui/core/styles';
import { BrandCanvasTable } from '../CoreComponents/BrandCanvasTable.js';
import { formatDate } from '../utils/DateUtils'
import { BrandModal } from '../CoreComponents/BrandModal'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { BrandLoaderSpinner } from '../CoreComponents/BrandLoader';
import { useStoreContext } from '../../store/Store';
import { postData } from '../utils/FetchUtils';
import { ButtonSize } from '../../constants/buttonConstants.js';
import { useTheme } from '@material-ui/styles';
import { updateColumnClass, textIsScientificNumber } from '../utils/ValidationUtils.js';
import { Box } from '@material-ui/core';
import { ColumnStatus } from '../../constants/UploadContants.js';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

const useStyles = makeStyles((theme) => ({
    colHeader: {
        background: `${theme.palette.background.mediumGray} 0% 0% no-repeat padding-box`,
        height: '44px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mappingSelect: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '16px',
        color: 'black',
        fontWeight: 'bold',
        width: '250px',
        minWidth: '250px',
        maxWidth: '250px',
        textAlign: 'center',
        backgroundColor: theme.palette.background.darkNuanceVersion2,
        border: `1px solid ${theme.palette.primary.lightGray}`,
        '&:not(last-child)': {
            borderRight: 'none',
        },
        '& .filled': {
            backgroundColor: theme.palette.primary.main,
            padding: '0.3em 0.6em'
        },
        '& .unfilledMandatory': {
            backgroundColor: theme.palette.primary.delete,
            padding: '0.3em 0.6em'
        },
        '& .unfilled': {
            backgroundColor: theme.palette.primary.attention,
            padding: '0.3em 0.6em'
        },
    },
    label: {
        font: 'normal normal bold 16px/21px Roboto',
    },
    mappingTop: {
        display: 'flex',
        marginTop: '1em'
    },
    mappingTable: {
        width: '100%',
        overflow: 'auto',
        scrollBehavior: 'smooth',
        '& .x-spreadsheet-bottombar': {
            display: 'none'
        },
        '&::-webkit-scrollbar': {
            height: '12px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.lightGray,
            borderRadius: '2px',
            border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.primary.darkGray,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.primary.black,
            borderRadius: '2px',
            border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
        },
    },
    serverMaps: {
        marginLeft: '2%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
    },
    serverMappingInfo: {
        display: 'flex',
        flexDirection: 'row',
        overflow: 'auto',
        width: 750
    },
    titleAndInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '30px',
    },
    title: {
        font: 'normal normal bold 30px/21px Roboto',
        marginBottom: theme.spacing(2),

    },
    reportName: {
        marginBottom: theme.spacing(2),
        font: 'normal normal normal 24px/20px Roboto',
    },
    table: {
        overflow: 'hidden',
        width: '100%',
        '& .x-spreadsheet': {
            marginTop: '-50px',
            marginLeft: '-59px',
        },
        '& .x-spreadsheet-overlayer': {
            display: 'none !important'
        },
        '& .x-spreadsheet-scrollbar': {
            display: 'none !important',
            '& .vertical': {
                display: 'none !important'
            }
        },
    },
    vertical: {
        height: '85vh',
        display: 'inline-block',
        border: `1px solid ${theme.palette.background.grayNuance}`,
        marginLeft: '8px',
        marginRight: '16px'
    },
    loader: {
        display: 'flex',
        justifyContent: "center",
        marginTop: theme.spacing(2)
    },
    saveMappingsModalBtnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px',
        marginTop: '30px'
    },
    marginLeft: {
        marginLeft: '16px',
    },
    greenText: {
        marginLeft: '4px',
        color: theme.palette.primary.main
    },

    mainContainer: {
        position: 'relative',
        width: '100%',
        height: '70%',
    },
    scrollButton: {
        position: 'absolute',
        top: '50%',
        width: "40px",
        height: "40px",
        zIndex: 2,

        cursor: 'pointer',
        pointerEvents: 'auto',
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 6px 12px ${theme.palette.primary.black}`,
        borderRadius: "50%",

        display: "flex",
        justifyContent: "center",
        alignItems: 'center',

        '&:hover': {
            opacity: 0.9,
        },
    },
    leftScrollIcon: {
        left: 20,
    },
    rightScrollIcon: {
        right: 20,
    },
    tableWrapper: {
        overflow: 'auto',
        height: '100%',
        position: "relative",
    },
}));

const REVENUE_REPLACE_REGEX = /[^\d.eE-]/g;
const WRONG_CSV_FORMAT_REGEX = /=?['"“”‘’„”]([^'"“”‘’„”]*)['"“”‘’„”]/;
const REVENUE_MATCH_REGEX = /[-+]?(?:\d+\.?\d*|\.\d+)(?:[eE][-+]?\d+)?/g;

const GetServerMappingInfoText = ({ dataMappings }) => {
    const styles = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedInfoMapKey, setSelectedInfoMapKey] = useState('');
    const [selectedMappingOptions, setSelectedMappingOptions] = useState([]);
    const [mappingNames] = useState(Object.keys(dataMappings));

    useEffect(() => {
        if (!selectedInfoMapKey) {
            setSelectedMappingOptions(['Please select a column!'])
        } else if (dataMappings[selectedInfoMapKey]) {
            setSelectedMappingOptions(dataMappings[selectedInfoMapKey].alternativeNames);
        }
    }, [selectedInfoMapKey]);

    return (
        <div className={styles.serverMappingInfo}>
            <div>
                <h3>Required Columns</h3>
                <List>
                    {mappingNames.map((x, indx) =>
                        <ListItem
                            button
                            key={x + '-' + indx}
                            selected={selectedIndex === indx}
                            onClick={() => {
                                setSelectedIndex(indx);
                                setSelectedInfoMapKey(x);
                            }}
                        >
                            <ListItemText primary={x} />
                        </ListItem>
                    )}
                </List>
            </div>
            <hr className={styles.vertical} />
            <div className={styles.serverMaps}>
                <h3>Automatic Mapping</h3>
                {selectedMappingOptions.map((x, indx) => <p key={x + '-' + indx}>{x}</p>)}
            </div>
        </div>
    )
};

export default function Mapping({
    reportingCompany,
    reportType,
    csvContentAndMappings,
    setCsvContentAndMappings,
    setMapDone,
    setValidationRows,
    dataMappings,
    setDataMappings,
    mappingTemplate,
    mappingColumns,
    isProcessingFile,
    setIsProcessingFile,
    isMapClicked,
    setIsMapClicked,
    handleNext,
    isNewMappingsSaved,
    setIsNewMappingsSaved,
    didWentBack,
    validationRows,
    retryUpload,
    fileMetadata,
}) {
    const styles = useStyles();
    const theme = useTheme();
    const tableRef = useRef(null);

    const SCROLL_AMOUNT = 250;

    const DEFAULT_TABLE_HEIGHT = 582;
    const TABLE_WIDTH = mappingTemplate.length * 250 + 210;

    const ROW_HEGIHT = 50;
    const COL_WIDTH = 250;

    const blackIcon = {
        color: 'black',
    }

    const tableStyleIDs = {
        RegularColumn: 0,
        UnfilledMandatoryColumn: 1,
    }
    const [tableStyles] = useState([
        {   // Regular Column
            bgcolor: theme.palette.background.darkNuanceVersion2,
            color: theme.palette.text.primary,

        },
        {   // Column With Unfilled Mandatory Data
            bgcolor: theme.palette.background.darkNuanceVersion2,
            color: theme.palette.primary.alert,
        },
    ]);
    const [tableOptions, setTableOptions] = useState({
        mode: 'read',
        showToolbar: false,
        showContextmenu: false,
        autoFill: false,
        view: {
            height: () => DEFAULT_TABLE_HEIGHT,
            width: () => TABLE_WIDTH
        },
        row: {
            height: ROW_HEGIHT,
        },
        col: {
            len: mappingTemplate.length,
            width: COL_WIDTH
        },
        style: {
            align: 'left',
            textWrap: true,
            font: {
                name: 'Roboto',
                size: 11,
                bold: false,
                italic: false,
            },
            border: {
                top: ['thin', theme.palette.background.darkNuanceVersion2],
                bottom: ['thin', theme.palette.background.darkNuanceVersion2],
                right: ['thin', theme.palette.background.mediumGray],
                left: ['thin', theme.palette.background.mediumGray],
            }
        }
    });
    const [state, setState] = useStoreContext();
    const [showMappingInfoModal, setShowMappingInfoModal] = useState(false);
    const [fileColumnNames, setFileColumnNames] = useState([]);
    const [autodetected, setAutodetected] = useState({});
    const [autoDetectedTrigger, setAutoDetectedTrigger] = useState(false);
    const [autoDetect, setAutoDetect] = useState(false);

    const [tableRows, setTableRows] = useState([]);
    const [saveMappingModal, setSaveMappingModal] = useState(false);
    const [isAllAlternativeNamesEqual, setIsAllAlternativeNamesEqual] = useState(true);
    const [loaded, setLoaded] = useState(0);

    const [showLeftScroll, setShowLeftScroll] = useState(false);
    const [showRightScroll, setShowRightScroll] = useState(true);

    const getMandatoryColumnsCount = () => {
        return Object.values(dataMappings)?.filter(o => o && o.required).length;
    }

    const getTableViewHeight = (numRows) => {
        return numRows * 59.4 + 140 / numRows
    }

    useEffect(() => {
        setLoaded(loaded + 1)
        if (loaded > 1) {
            setIsNewMappingsSaved(false);
        }
        setIsAllAlternativeNamesEqual(false)
    }, [tableOptions, csvContentAndMappings])

    useEffect(() => {
        const { csvContent } = csvContentAndMappings;
        if (csvContent.length === 0) {
            setIsProcessingFile(false);
            return;
        }

        const { manualMapping, mappingRow } = csvContentAndMappings;
        setFileColumnNames(Object.keys(mappingRow).map((name) => ({ name, id: name })));

        const fileMapping = {};
        const foundMappings = {};

        mappingTemplate.forEach((column) => {
            const manualMap = manualMapping[column];
            fileMapping[column] = manualMap || [];

            const isRequired = dataMappings[column]?.required;
            const columnElement = document.getElementById(column);

            if (manualMap) {
                updateColumnClass(column, ColumnStatus.UnfilledMandatory.name, true);
                updateColumnClass(column, ColumnStatus.Unfilled.name, true);

                if (isRequired) {
                    foundMappings[column] = true;
                }
            } else if (!autoDetect) {
                const { alternativeNames } = dataMappings[column];
                const matchedColumn = alternativeNames
                    .map((name) => findMatchingKey(name, mappingRow))
                    .find((key) => mappingRow[key]);

                if (matchedColumn) {
                    fileMapping[column] = [matchedColumn];
                    updateManualMapping(column, matchedColumn, manualMapping);
                    updateColumnClass(column, ColumnStatus.Filled.name, false);

                    if (isRequired) {
                        foundMappings[column] = true;
                    }
                } else {
                    updateColumnClass(column, ColumnStatus.Filled.name, true);
                    updateColumnClass(
                        column,
                        isRequired
                            ? ColumnStatus.UnfilledMandatory.name
                            : ColumnStatus.Unfilled.name,
                        false
                    );
                }
            } else {
                updateColumnClass(column, ColumnStatus.Filled.name, true);
                updateColumnClass(
                    column,
                    isRequired
                        ? ColumnStatus.UnfilledMandatory.name
                        : ColumnStatus.Unfilled.name,
                    false
                );
            }

            if (checkClassStatus(columnElement) === ColumnStatus.Neither.name) {
                updateColumnClass(column, ColumnStatus.Filled.name, false);
            }
        });


        const isMapDone = Object.keys(foundMappings).length === getMandatoryColumnsCount();
        setMapDone(isMapDone);

        const rows = [];
        const revenueIndex = mappingTemplate.indexOf('Revenue');
        const upcIndex = mappingTemplate.indexOf('UPC');

        csvContent
            .filter((row) => !Object.values(row).every((x) => x === ''))
            .forEach((row, rowIndex) => {
                rows.push({ cells: {} });

                mappingTemplate.forEach((column, columnIndex) => {
                    const columnResult = fileMapping[column].reduce((result, mappingColumn, i) => {
                        const value = row[mappingColumn] || '';
                        return result + (i === 0 ? value : ' ' + value);
                    }, '');

                    const elem = document.getElementById(column);
                    let redText = false; // Paint the column red if it's mandatory
                    if (checkClassStatus(elem) === ColumnStatus.UnfilledMandatory.name) {
                        redText = true;
                    }

                    if (columnResult.length > 0) {
                        let text = columnResult.replace(/[\uFFFD]/, ' ')?.trim();
                        const cellText = determineCellTextContent(text, columnIndex, revenueIndex, upcIndex);
                        rows[rowIndex].cells[columnIndex] = { text: cellText, style: tableStyleIDs.RegularColumn };
                    } else if (columnIndex === revenueIndex) {
                        rows[rowIndex].cells[columnIndex] = { text: '0.00', style: redText ? tableStyleIDs.UnfilledMandatoryColumn : tableStyleIDs.RegularColumn };
                    } else {
                        rows[rowIndex].cells[columnIndex] = { text: 'No Data', style: redText ? tableStyleIDs.UnfilledMandatoryColumn : tableStyleIDs.RegularColumn };
                    }
                });
            });

        for (const key in dataMappings) {
            const isColumnFilled = Object.keys(manualMapping).includes(dataMappings[key].fileName);

            dataMappings[key].alternativeNames = isColumnFilled
                ? dataMappings[key].alternativeNames
                : [];
        }

        setDataMappings(dataMappings);
        setTableRows(rows.slice(0, 10));

        if (!didWentBack) {
            setValidationRows(rows);
        } else {
            validationRows.forEach((row, i) => {
                Object.keys(row.cells).forEach((cellKey, j) => {
                    validationRows[i].cells[cellKey].text = rows[i].cells[j].text;
                });
            });
            setValidationRows(validationRows);
        }

        setAutodetected(manualMapping);
        setAutoDetectedTrigger(true);
        setAutoDetect(true);

        setTableOptions({
            ...tableOptions,
            row: {
                ...tableOptions.row,
                len: csvContent.length
            },
            view: {
                width: () => TABLE_WIDTH,
                height: () => Math.min(DEFAULT_TABLE_HEIGHT, getTableViewHeight(rows.length))
            }
        });

        setIsProcessingFile(false);
    }, [csvContentAndMappings, fileMetadata, mappingTemplate, dataMappings]);

    useEffect(() => {
        if (isMapClicked) {
            if (!isAllAlternativeNamesEqual && !isNewMappingsSaved) {
                setSaveMappingModal(true)
            } else {
                handleNext()
            }
        }
    }, [isMapClicked])

    //Scroll buttons logic
    useEffect(() => {
        const container = tableRef.current;

        const handleScroll = () => {
            if (container) {
                setShowLeftScroll(container.scrollLeft > 0);
                setShowRightScroll(container.scrollLeft + container.clientWidth < container.scrollWidth);
            }
        };

        if (container) {
            container.addEventListener('scroll', handleScroll);
            handleScroll();
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    function determineCellTextContent(text, columnIndex, revenueIndex, upcIndex) {
        const wrongFormatMatch = text.match(WRONG_CSV_FORMAT_REGEX);

        if (wrongFormatMatch) {
            text = wrongFormatMatch[1];
        }

        if (columnIndex === upcIndex) {
            return textIsScientificNumber(text) ? parseFloat(text).toString() : text;
        }
        
        if (columnIndex !== revenueIndex) {
            return text;
        }
        
        if (text.length === 0) {
            return text?.trim();
        }

        const isNegativeSpecial = text.charAt(0) === '(' && text.charAt(text.length - 1) === ')';
        const revenueMatch = text.replaceAll(REVENUE_REPLACE_REGEX, '').match(REVENUE_MATCH_REGEX);

        if (!revenueMatch || revenueMatch.length === 0) {
            return text?.trim();
        }

        const rev = parseFloat(revenueMatch[0]) * (isNegativeSpecial ? -1 : 1);
        return rev.toString();
    }

    function updateManualMapping(column, potentialMapColumn, manualMapping) {
        if (!manualMapping[column]) {
            manualMapping[column] = [];
        }

        if (!manualMapping[column].includes(potentialMapColumn)) {
            manualMapping[column] = [potentialMapColumn];
        }
    }

    function findMatchingKey(potentialMapColumn, mappingRow) {
        for (const key in mappingRow) {
            if (key.toLocaleLowerCase() === potentialMapColumn.toLowerCase()) {
                return key;
            }
        }

        return potentialMapColumn;
    }

    const checkClassStatus = (element) => {
        if (!element || !element.classList) {
            return ColumnStatus.Neither.name;
        }

        if (element.classList.contains(ColumnStatus.Filled.name)) {
            return ColumnStatus.Filled.name;
        } else if (element.classList.contains(ColumnStatus.UnfilledMandatory.name)) {
            return ColumnStatus.UnfilledMandatory.name;
        } else if (element.classList.contains(ColumnStatus.Unfilled.name)) {
            return ColumnStatus.Unfilled.name;
        } else {
            return ColumnStatus.Neither.name;
        }
    }

    const saveMappingHandler = (e) => {
        const btnValue = e.target.innerText
        setIsMapClicked(false)
        setSaveMappingModal(false)
        if (btnValue === "YES") {
            postData(process.env.REACT_APP_SERVER_HOST + '/api/admin/mappings', { autodetected, reportingCompany })
                .then(res => {
                    if (res.success) {
                        handleNext();
                        setIsNewMappingsSaved(true);
                    }
                })
                .catch(err => {
                    console.error(err.message);
                })
        } else if (btnValue === 'NO') {
            handleNext();
            setIsNewMappingsSaved(true);
        }
    }

    const getBorderRadiusStyle = (index, arrayLength) => ({
        borderTopLeftRadius: index === 0 ? '6px' : 0,
        borderTopRightRadius: index === arrayLength - 1 ? '6px' : 0,
    });

    const handleScrollRight = () => {
        const container = tableRef.current;
        if (container) {
            container.scrollLeft += SCROLL_AMOUNT;
        }
    }

    const handleScrollLeft = () => {
        const container = tableRef.current;
        if (container) {
            container.scrollLeft -= SCROLL_AMOUNT;
        }
    }

    return (
        <React.Fragment>
            <BrandModal
                open={saveMappingModal}
                onClose={() => {
                    setSaveMappingModal(false)
                    setIsMapClicked(false)
                }}
            >
                <div>Do you want to save this mapping for future use?</div>
                <div className={styles.saveMappingsModalBtnContainer}>

                    <TempSecondaryBrandButton
                        size={ButtonSize.SMALL}
                        capitalize={true}
                        onClick={saveMappingHandler}
                    >
                        no
                    </TempSecondaryBrandButton>
                    <TempBrandDeleteButton
                        size={ButtonSize.SMALL}
                        capitalize={true}
                        onClick={saveMappingHandler}
                    >
                        yes
                    </TempBrandDeleteButton>
                </div>
            </BrandModal>
            <BrandModal
                open={showMappingInfoModal}
                onClose={() => setShowMappingInfoModal(false)}
            >
                <GetServerMappingInfoText dataMappings={dataMappings} />
            </BrandModal>

            <BrandModal
                open={isProcessingFile}
                onClose={() => setIsProcessingFile(false)}
                notClosable={true}
            >
                <div className={styles.loader}>
                    <BrandLoaderSpinner />
                </div>
            </BrandModal>

            <Box className={styles.titleAndInfo}>
                <div className={styles.title}>
                    2. Mapping
                </div>
                <TempBrandButton onClick={() => setShowMappingInfoModal(true)} bold>
                    Mapping Info
                </TempBrandButton>
            </Box>
            <Box className={styles.reportName}>
                Report Name:<span className={styles.greenText}> {fileMetadata.path} </span>
                {fileMetadata.customDatePeriod &&
                    <span className={styles.marginLeft}>Report Period:
                        <span className={styles.greenText}>{formatDate(fileMetadata.startDate)} - {formatDate(fileMetadata.endDate)}</span>
                    </span>
                }
            </Box>

            <div className={styles.mainContainer}>
                <div className={styles.mappingTable} ref={tableRef}>
                    <div className={styles.mappingTop}>
                        {mappingTemplate.map((column, index) => {
                            const value = column;
                            const status = checkClassStatus(document.getElementById(column))
                            return (
                                <div
                                    className={styles.mappingSelect}
                                    key={index}
                                    style={getBorderRadiusStyle(index, mappingTemplate.length)}
                                >
                                    <div className={styles.colHeader} id={value} style={getBorderRadiusStyle(index, mappingTemplate.length)}>
                                        <span className={styles.label}>{dataMappings[value] && dataMappings[value].required ? `${value}*` : value}</span>
                                    </div>
                                    <BrandMultiSelect
                                        label={value.toString()}
                                        value={fileColumnNames}
                                        autodetected={autodetected}
                                        autoDetectedTrigger={autoDetectedTrigger}
                                        templateCol={value}
                                        mappingColumns={mappingColumns}
                                        status={status}
                                        onChange={(args) => {
                                            if (!state.findSongsInDb) {
                                                if (!retryUpload) {
                                                    setState(state => (state.findSongsInDb = true, { ...state }));
                                                }
                                            }
                                            const columnName = value.toString();
                                            if (args.length === 0) {
                                                setCsvContentAndMappings(csvContentAndMappings => (delete csvContentAndMappings.manualMapping[columnName], { ...csvContentAndMappings }));
                                            } else if (args.length > 1) {
                                                setCsvContentAndMappings(csvContentAndMappings => (csvContentAndMappings.manualMapping[columnName] = args, { ...csvContentAndMappings }));
                                            } else {
                                                let manualMap = [args[0]];
                                                setCsvContentAndMappings(_ => (csvContentAndMappings.manualMapping[columnName] = manualMap, { ...csvContentAndMappings }));
                                            }
                                        }}
                                    />
                                </div>);
                        })}
                    </div>

                    {showLeftScroll && (
                        <div className={`${styles.scrollButton} ${styles.leftScrollIcon}`} onClick={handleScrollLeft}>
                            <ChevronLeftRoundedIcon style={blackIcon} />
                        </div>
                    )}

                    <BrandCanvasTable
                        options={tableOptions}
                        rows={tableRows}
                        className={styles.table}
                        style={{ width: mappingTemplate.length * 250 }}
                        styles={tableStyles}
                    />

                    {showRightScroll && (
                        <div className={`${styles.scrollButton} ${styles.rightScrollIcon}`} onClick={handleScrollRight}>
                            <ChevronRightRoundedIcon style={blackIcon} />
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
